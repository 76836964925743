import React, { Component, Fragment } from "react";
import blog1 from "../assets/images/blog/blog-01.jpg";
import newsJson from "../assets/news.json";


class SilveradoNewsList extends Component {
  render() {
    return (
      <Fragment>
        <div className="row">
          {newsJson.map((value, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="blog blog-style--1">
                <div className="thumbnail">
                  <a href="/blog-details">
                    <img
                      className="w-100"
                      src={blog1}
                      alt="Blog Images"
                    />
                  </a>
                </div>
                <div className="content">
                  <p className="blogtype">{value["category"]}</p>
                  <h4 className="title">
                    <a href="/blog-details">{value["title"]}</a>
                  </h4>
                  <div className="blog-btn text-white">
                    {/* <a className="rn-btn text-white" href="/blog-details">
                      Read More
                    </a> */}
                    contenuto {i}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default SilveradoNewsList;
