import React, { Component } from "react";
import {Helmet} from 'react-helmet'

const SilveradoHelmet = (props) => {
    return <>
        <Helmet>
            <title>{props.pageTitle} </title>
            {props.description && <meta name="description" content={props.description} />}
        </Helmet>
    </>
}

export default SilveradoHelmet;
