import React, { useEffect, useState } from "react";
import {
  // FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import logoSilverado from "../../assets/images/logo/silverado-logo.png";
import { useLocation } from 'react-router-dom';


const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  // { Social: <FaTwitter />, link: "https://twitter.com/" },
];
const SilveradoHeader = (props) => {
  const {pathname} = useLocation()
  const isHomePage = pathname === '/'
  const [color, setColor] = useState("default-color")
  const [socialColor, setSocialColor] = useState("color-black")

  useEffect(() => {
    setColor(isHomePage ? 'default-color' : 'color-black')
    setSocialColor(isHomePage ? "default-color" : "color-black")
  }, [pathname])
  
  const onScroll = () => {
    const value = window.scrollY;
    if (value > 100) {
      document.querySelector(".header--fixed").classList.add("sticky");
      setSocialColor('color-black')
    } else {
      document.querySelector(".header--fixed").classList.remove("sticky");
      setSocialColor(isHomePage ? 'default-color' : 'color-black')
    }
  }

  useEffect(() => {
    // window.addEventListener("load", function () {
    //   console.log("All assets are loaded");
    // });

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [pathname])

  function menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  function CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  function closeSidebar() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  function stickyHeader() {}

  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement
          .querySelector(".submenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  let logoUrl = <img src={logoSilverado} alt="Silverado" />;

  return (
    <header className={`silverado-header header-area header-style-two header--fixed ${color}`}>
      <div className="header-wrapper">
        <div className="header-left d-flex align-items-center">
          <div className="logo">
            <a href={props.homeLink}>{logoUrl}</a>
          </div>
          <nav className="mainmenunav d-lg-block ml--50">
            <ul className="mainmenu">
              <li>
                <Link onClick={closeSidebar} to="/">Home</Link>
              </li>
              <li className="has-dropdown">
                <a href="#">
                  Ribs
                </a>
                <ul className="submenu">
                  <li>
                    <Link to="/silverado-8">Silverado 8</Link>
                  </li>
                  <li>
                    <Link to="/silverado-10">Silverado 10</Link>
                  </li>
                  <li>
                    <Link to="/silverado-13">Silverado 13</Link>
                  </li>
                  <li>
                    <Link to="/silverado-e">Silverado elettrico</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/s13configurator">Configuratore</Link>
              </li>
              <li>
                <Link to="/about-us">About us</Link>
              </li>
              <li>
                  <Link to="/news">News</Link>
              </li>
              <li>
                  <Link to="/contacts">Contatti</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner">
            <ul className={`social-share social-style--2 ${socialColor} d-flex justify-content-start liststyle`}>
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`}>{val.Social}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="header-btn">
            <Link
              className="rn-btn"
              to="/s13configurator"
            >
              <span>CONFIGURA</span>
            </Link>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span
              onClick={menuTrigger}
              className="menutrigger text-white"
            >
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={CLoseMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SilveradoHeader;
