import React, { Component } from "react";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import SilveradoHelmet from "../component/common/SilveradoHelmet";
import SilveradoNewsList from "../elements/SilveradoNewsList";

class News extends Component{
    render(){
        return(
            <React.Fragment>
                <SilveradoHelmet pageTitle='Blog' />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Blog List'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <SilveradoNewsList />
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination />
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
            </React.Fragment>
        )
    }
}
export default News;
