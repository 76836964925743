import React, { Component , Fragment } from "react";
// import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import SilveradoHeader from "../header/SilveradoHeader";
import SilveradoFooter from "../footer/SilveradoFooter";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ScrollToTop from "react-scroll-to-top";
import { NexusAppProvider } from "../../contexts/NexusAppContext";

const _SilveradoApp = (props) => {
    const openWhatsapp = () => {
        window.open('https://api.whatsapp.com/send/?phone=123456789')
    }

    return <Fragment>
        <SilveradoHeader homeLink="/" />
        {props.children}

            {/* Start Back To Top */}
            {/* <div className="backto-top">
            <ScrollToTop showUnder={160} style={{bottom: '120px', right: '38px'}}>
                <FiChevronUp />
            </ScrollToTop>
        </div> */}
        <div className="silverado-back-to-top">
            <ScrollToTop smooth component={<FiChevronUp />} />
        </div>
        {/* End Back To Top */}
        <FloatingWhatsApp notification={false} onClick={openWhatsapp} chatboxHeight={0} />
        <SilveradoFooter />
            
    </Fragment>
}

const SilveradoApp = (props) => {
    return <NexusAppProvider>
        <_SilveradoApp>
            {props.children}
        </_SilveradoApp>
    </NexusAppProvider>
}

export default SilveradoApp;
