import React, { Component } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import logo from "../../assets/images/logo/silverado-logo.png";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  // { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class SilveradoFooter extends Component {
  render() {
    return (
        <React.Fragment>
          <footer className="silverado-footer">
            <div>
              <div className="silverado-footer__upper">
                <Row>
                  <Col lg={4} sm={4} xs={12}>
                    <Stack gap={1}>
                      <img  className="mb--20 silverado-footer__logo" src={logo} />
                      <h4 className="mb--0">Silverado</h4>
                      <div>Onde alte</div>
                    </Stack>
                  </Col>
                  {/* Start Single Widget  */}
                  <Col lg={4} sm={4} xs={12}>
                    <h4>Quick Link</h4>
                    <ul>
                      <li>
                        <a href="/privacy">Privacy</a>
                      </li>
                      <li>
                        <a href="/portfolio">Work</a>
                      </li>
                      <li>
                        <a href="/about">About</a>
                      </li>
                      <li>
                        <a href="/contact">Let's Talk</a>
                      </li>
                    </ul>
                  </Col>
                  {/* End Single Widget  */}
                  {/* Start Single Widget  */}
                  <Col lg={4} sm={4} xs={12} className="mt_mobile--20">
                    <h4>Say Hello</h4>
                    <ul>
                      <li>
                        <a href="mailto:admin@example.com">
                          admin@example.com
                        </a>
                      </li>
                      <li>
                        <a href="mailto:hr@example.com">hr@example.com</a>
                      </li>
                    </ul>

                    <div className="social-share-inner">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                        {SocialShare.map((val, i) => (
                            <li key={i}>
                              <a href={`${val.link}`}>{val.Social}</a>
                            </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  {/* End Single Widget  */}
                </Row>
              </div>
              <div className="d-flex justify-content-center silverado-footer__copyright">
                <p>
                  Copyright © 2024 Rainbow-Themes. All Rights Reserved.
                </p>
              </div>
            </div>
          </footer>
        </React.Fragment>
    );
  }
}
export default SilveradoFooter;
