const SilveradoPageHead = (props) => {
    return <>
        <div className="fullscreen silverado-page-head">
            <div className="fullscreen silverado-page-head__wrapper">
                {props.children}
            </div>
            <div className="fullscreen empty-div silverado-page-head__placeholder"></div>
        </div>
    </>
}
export default SilveradoPageHead