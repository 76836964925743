import { Col, Container, Row, Stack, Table } from "react-bootstrap"
import sacBoatImage from "../assets/images/sacs-boat.jpg"
import { Parallax } from "react-parallax"
import boat6Img from "../assets/images/3d-boats/6.jpg"
import boat8Img from "../assets/images/3d-boats/8.jpg"
import React from "react";
import SilveradoHelmet from "../component/common/SilveradoHelmet"
import Silverado13Tabs from "../elements/tab/Silverado13Tabs"
import SilveradoContainerSection from "../elements/common/SilveradoContainerSection"


const Silverado13 = () => {
    return <>
        <SilveradoHelmet pageTitle="Silverado 13" />

        <div className="silverado13-wrapper">
            <section>
                <Parallax bgImage={sacBoatImage} strength={700}>
                    <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="inner text-center">
                                        <h1 className="title theme-gradient">
                                            Silverado 13
                                        </h1>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Parallax>
            </section>

            <SilveradoContainerSection className="section-desc">
                <Row>
                    <Col>
                        <h2>Prestazioni, forza e potenza</h2>

                        Benvenuti a bordo del nostro nuovo maxi RIB di alta gamma, progettato per un'esperienza senza pari, con un confort di bordo estremamente unica.
                        Il nostro modello SILVERADO, unisce prestazioni di alto livello a un design filante e lineare lungo 13.7 metri.

                        Con il suo profilo slanciato e l'eleganza senza tempo, il SILVERADO porta la firma del conosciuto designer di yacht Enzo Manca, garanzia di stile e prestigio.
                        Ispirato agli stili americani, il SILVERADO incarna l'eleganza raffinata e l'attenzione ai dettagli caratteristiche di questi iconici design.

                        Il punto di forza del SILVERADO risiede nella sua versatilità e funzionalità.
                        Grazie all'attento studio dell'articolazione degli spazi esterni ed interni, offre un'esperienza di navigazione superiore, combinando prestazioni eccezionali con un comfort senza compromessi.

                        All'interno, il SILVERADO sorprende con una cabina sottocoperta spaziosa e accogliente, progettata per garantire il massimo comfort durante le lunghe crociere.
                        Dotata di un bagno indipendente, la cabina può ospitare comodamente fino a 4 persone, offrendo un rifugio tranquillo e lussuoso lontano dalla frenesia del mare.

                        Grazie alla sua costruzione di alta qualità e alla sua tecnologia avanzata, il SILVERADO offre una navigazione fluida e potente, garantendo emozioni senza precedenti sia per gli appassionati di sport acquatici che per gli amanti delle crociere rilassanti.

                        Preparati a vivere un'avventura senza confini con il SILVERADO 13,70,  il compagno ideale per esplorare i mari con stile, comfort e prestazioni di alto livello.

                    </Col>

                    <Col className="table-wrapper">
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td>Cantiere costruttore:</td>
                                    <td>NAUTICA CAB - Italy</td>
                                </tr>
                                <tr>
                                    <td>Progetto carena:</td>
                                    <td>TDF - THE DESIGN FACTORY (Claudio Locarno e Lorenzo Vienna) - Italy</td>
                                </tr>
                                <tr>
                                    <td>Progetto Coperta:</td>
                                    <td>Enzo Manca Design - Italy</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SilveradoContainerSection>

            <SilveradoContainerSection className="bg_color--5">
                <Row>
                    <Col lg={8}>
                        <Silverado13Tabs />
                    </Col>

                    <Col lg={4}>
                        <Stack gap={1}>
                            <img src={boat6Img} />
                            <img src={boat8Img} />
                        </Stack>
                    </Col>
                </Row>
            </SilveradoContainerSection>
        </div>
    </>
}
export default Silverado13