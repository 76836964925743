/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/chair.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
import { useContext } from 'react'
import { CustomizationContext } from '../contexts/CustomizationContext'

const Chair = (props) => {
  const { nodes, materials } = useGLTF('/models/chair.gltf')

  const texture = useTexture({
    // map: './textures/wood/Substance_Graph_BaseColor.jpg',
    normalMap: '/textures/wood/Substance_Graph_Normal.jpg',
    roughnessMap: '/textures/wood/Substance_Graph_Roughness.jpg',
    aoMap: '/textures/wood/Substance_Graph_AmbientOcclusion.jpg',
    // displacementMap: './textures/wood/Substance_Graph_Height.jpg'
  })

  // texture.map.repeat.set(2, 2)
  texture.normalMap.repeat.set(2, 2)
  texture.roughnessMap.repeat.set(2, 2)
  texture.aoMap.repeat.set(2, 2)
  // texture.displacementMap.repeat.set(2, 2)

  
  // texture.map.wrapS = texture.map.wrapT = 
  texture.normalMap.wrapS = texture.normalMap.wrapT = 
  texture.roughnessMap.wrapS = texture.roughnessMap.wrapT = 
  texture.aoMap.wrapS = texture.aoMap.wrapT = 
  THREE.RepeatWrapping
  // texture.displacementMap.wrapS = texture.displacementMap.wrapT = 

  const {customizations} = useContext(CustomizationContext)

  return (
    <group {...props} dispose={null}>
      {/* <mesh geometry={nodes.Chair.geometry} material={materials.Chair} /> */}
      <mesh geometry={nodes.Chair.geometry} >
        <meshStandardMaterial {...texture} color={customizations["Tuga e coperta"]?.color.color} />
      </mesh>
      <mesh geometry={nodes.Cushion.geometry} material={materials.Cushion} position={[0, 0.064, 0.045]}>
        <meshStandardMaterial color={customizations["Carena"]?.color.color} />
      </mesh>
      <mesh geometry={nodes.Legs1.geometry} material={materials.Legs} />
      <mesh geometry={nodes.Legs2.geometry} material={materials.Legs} />
    </group>
  )
}

useGLTF.preload('/models/chair.gltf')

export default Chair
