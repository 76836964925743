import axios from "axios"


export const API_URL = process.env.REACT_APP_API_URL
export const API_URL_INDEX = `${API_URL}/index.php?version=1.0.0`


const isNotEmpty = (obj) => obj !== undefined && obj !== null && obj !== ''


export async function genericApiPost(url, dataParameters, config) {
    const axiosResponse = await axios.post(url, dataParameters, config)
    const {data} = axiosResponse
    if(!config?.hideToastError && !data.result) {
        // toast.error(data.message)
    } else if(!config?.hideToastSuccess && isNotEmpty(data.message)) {
        // toast.success(data.message)
    }
    return axiosResponse
}


export function setupAxios() {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data'
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
    (config) => {
        config.url += '&XDEBUG_SESSION_START=react'
        // const auth = getAuth()
        const auth = false
        if (auth) {
            if(!config.data) {
                config.data = {}
            }
            config.data.login_users_admin_username= auth.login_users_admin_username
            config.data.login_users_admin_password= auth.login_users_admin_password
        }
        return config
    },
    (err) => Promise.reject(err)
    )
    axios.interceptors.response.use(
        (res) => res,
        (err) => {
            // toast.error('Server error')
            return Promise.reject(err);
        }
    )
}