// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Demo from "./page-demo/Demo";
import MainDemo from "./home/MainDemo";
import Startup from "./home/Startup";
import Paralax from "./home/Paralax";
import HomePortfolio from "./home/HomePortfolio";
import DigitalAgency from "./home/DigitalAgency";
import CreativeAgency from "./home/CreativeAgency";
import PersonalPortfolio from "./home/PersonalPortfolio";
import Business from "./home/Business";
import StudioAgency from "./home/StudioAgency";
import PortfolioLanding from "./home/PortfolioLanding";
import CreativeLanding from "./home/CreativeLanding";
import HomeParticles from "./home/HomeParticles";
import CreativePortfolio from "./home/CreativePortfolio";
import DesignerPortfolio from "./home/DesignerPortfolio";
import InteriorLanding from "./home/Interior";
import CorporateBusiness from "./home/CorporateBusiness";
import InteractiveAgency from "./home/InteractiveAgency";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";
import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contacts from "./pages/Contacts";
import News from "./pages/News";
import Privacy from "./pages/Privacy";
import Silverado8 from "./pages/Silverado8";
import Silverado10 from "./pages/Silverado10";
import Silverado13 from "./pages/Silverado13";
import SilveradoElettrico from "./pages/SilveradoElettrico";
import Silverado13Configurator from "./pages/Silverado13Configurator";
import SilveradoApp from "./component/common/SilveradoApp";
import Silverado404 from "./pages/Silverado404";
import { setupAxios } from "./http/http";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/main-demo`}
              component={MainDemo}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/dark-main-demo`}
              component={DarkMainDemo}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/startup`}
              component={Startup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/paralax`}
              component={Paralax}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/digital-agency`}
              component={DigitalAgency}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/creative-agency`}
              component={CreativeAgency}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/personal-portfolio`}
              component={PersonalPortfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studio-agency`}
              component={StudioAgency}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/business`}
              component={Business}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-home`}
              component={HomePortfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-landing`}
              component={PortfolioLanding}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/creative-landing`}
              component={CreativeLanding}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/home-particles`}
              component={HomeParticles}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`}
              component={DarkPortfolioLanding}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/designer-portfolio`}
              component={DesignerPortfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/creative-portfolio`}
              component={CreativePortfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/interior-landing`}
              component={InteriorLanding}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/corporate-business`}
              component={CorporateBusiness}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/interactive-agency`}
              component={InteractiveAgency}
            />

            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={ServiceDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details`}
              component={PortfolioDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />

            {/* Blocks Elements  */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/counters`}
              component={Counters}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/testimonial`}
              component={Testimonial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio`}
              component={Portfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/video-popup`}
              component={VideoPopup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallery`}
              component={Gallery}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/clint-logo`}
              component={Brand}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/progressbar`}
              component={ProgressBar}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-form`}
              component={ContactForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/google-map`}
              component={GoogleMap}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/columns`}
              component={Columns}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/pricing-table`}
              component={PricingTable}
            />

            <SilveradoApp>
              <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                <Route exact path={`${process.env.PUBLIC_URL}/s13configurator`} component={Silverado13Configurator} />
                <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} />
                <Route exact path={`${process.env.PUBLIC_URL}/contacts`} component={Contacts} />
                <Route exact path={`${process.env.PUBLIC_URL}/news`} component={News} />
                <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
                <Route exact path={`${process.env.PUBLIC_URL}/silverado-8`} component={Silverado8} />
                <Route exact path={`${process.env.PUBLIC_URL}/silverado-10`} component={Silverado10} />
                <Route exact path={`${process.env.PUBLIC_URL}/silverado-13`} component={Silverado13} />
                <Route exact path={`${process.env.PUBLIC_URL}/silverado-e`} component={SilveradoElettrico} />
                <Route path={`${process.env.PUBLIC_URL}/*`} component={Silverado404} />
              </Switch>
            </SilveradoApp>
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

setupAxios()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
