import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";

export const NexusAppContext = createContext({});

export const NexusAppProvider = (props) => {
    const [appState, setAppState] = useState({})
    return <NexusAppContext.Provider value={{appState, setAppState}}>
        {props.children}
    </NexusAppContext.Provider>
}

export const useNexusAppContext = () => useContext(NexusAppContext);
