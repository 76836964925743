import { Container } from "react-bootstrap"

const SilveradoContainerSection = (props) => {
    return <>
        <section className={`ptb--120 ${props.className}`}>
            <Container>
                {props.children}
            </Container>
        </section>
    </>
}
export default SilveradoContainerSection