import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class Silverado13Tabs extends Component {
  render() {
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
            <Tabs>
                <TabList className="tab-style--1">
                <Tab>Dati tecnici</Tab>
                <Tab>Dotazioni standard</Tab>
                <Tab>Accessori</Tab>
                </TabList>

                <TabPanel>
                <div className="single-tab-content">
                    <Table borderless>
                        <tbody>
                            <tr><td>Lunghezza f.t. (m) 14,00</td></tr>
                            <tr><td>Larghezza 3,76 m. (Con tubolari gonfiati)</td></tr>
                            <tr><td>Larghezza 2,98 m. (Con tubolari sgonfiati)</td></tr>
                            <tr><td>Peso circa 7000 Kg a secco</td></tr>
                            <tr><td>Portata persone 18</td></tr>
                            <tr><td>Motori - 2 FB 450 – 2 FB 500 Mercury / Yamaha  – 2 FB 600 Mercury</td></tr>
                            <tr><td>Serbatoio carburante 1200 Litri</td></tr>
                            <tr><td>Serbatoio acqua dolce 250 Litri</td></tr>
                            <tr><td>Omologazione CE - B</td></tr>
                        </tbody>
                    </Table>
                </div>
                </TabPanel>

                <TabPanel>
                <div className="single-tab-content">
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td>Hard Top in vetroresina</td>
                                <td>Ancora acciaio inox 15kg con verricello 1000W e pannello digitale e Contametri</td>
                            </tr>
                            <tr>
                                <td>Coperta in teak o teak sintetico</td>
                                <td>Allestimento cabina in legno pregiato</td>
                            </tr>
                            <tr>
                                <td>Bussola, GPS 12 pollici con eco VHF in cabina e sul cruscotto</td>
                                <td>Impianto stereo 6 casse con amplificatore e iPod</td>
                            </tr>
                            <tr>
                                <td>TV in cabina con antenna digitale</td>
                                <td>Impianto doccia con autoclave e polmone 3 punti (cabina, prua e plancetta)</td>
                            </tr>
                            <tr>
                                <td>Toilette interna completa di cabina doccia e lavabo</td>
                                <td>Cucina due fuochi + lavabo esterno</td>
                            </tr>
                            <tr>
                                <td>Impianto acque nere (40 litri)</td>
                                <td>Impianto acque grigie (60 litri)</td>
                            </tr>
                            <tr>
                                <td>Luci di cortesia esterne</td>
                                <td>Luci notturne cabina</td>
                            </tr>
                            <tr>
                                <td>Illuminazione cabina a LED (tipo cielo stellato)</td>
                                <td>Passo uomo cabina</td>
                            </tr>
                            <tr>
                                <td>Pannello comando esterno 12 funzioni</td>
                                <td>Pannello comando interno 220/12V con magnetotermici</td>
                            </tr>
                            <tr>
                                <td>Tre stacca batteria elettrici (comando cabina)</td>
                                <td>Cuscineria completa cabina + cuscineria esterna (colori da valutare con l’armatore)</td>
                            </tr>
                            <tr>
                                <td>Teak calpestio interno</td>
                                <td>Luci di navigazione, Tromba, Faro, Antenne, Carica batteria 40A e cavo da banchina</td>
                            </tr>
                            <tr>
                                <td>Sette batterie 100A/h</td>
                                <td>Serbatoio carburante integrato Nello scafo (1.200 litri) completo di galleggiante</td>
                            </tr>
                            <tr>
                                <td>Serbatoio acqua (250 litri)</td>
                                <td>Tavolo esterno, Frigorifero personalizzato inox</td>
                            </tr>
                            <tr>
                                <td>Boiler 220W acqua calda</td>
                                <td>Corrimani vari e sei bitte ormeggio in acciaio inox</td>
                            </tr>
                            <tr>
                                <td>Due scalette a scomparsa su plancetta</td>
                                <td>Due pompe di sentina</td>
                            </tr>
                            <tr>
                                <td>Gonfiatore elettrico 12V</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                </TabPanel>

                <TabPanel>
                <div className="single-tab-content">
                    <Table borderless>
                        <tbody>
                            <tr><td>Gruppo elettrogeno 4kW</td></tr>
                            <tr><td>Aria condizionata 9000btu</td></tr>
                            <tr><td>Pannello solare – 2 Opzioni</td></tr>
                            <tr><td>Pilota automatico</td></tr>
                            <tr><td>Joystic Garmin</td></tr>
                            <tr><td>Monitor Garmin 16 pollici</td></tr>
                            <tr><td>Visore notturno FLIR</td></tr>
                            <tr><td>Telo chiusura completa poppa</td></tr>
                            <tr><td>Elica di prua</td></tr>
                            <tr><td>Corrimano prua</td></tr>
                            <tr><td>Tavolino pranzo prua</td></tr>
                            <tr><td>Tendalino prua con pali in carbonio</td></tr>
                            <tr><td>Doppia scaletta inox a poppa</td></tr>
                            <tr><td>Barra luci led T-Top</td></tr>
                            <tr><td>Impianto stereo FUSION completo 9 casse + Sub</td></tr>
                            <tr><td>Sedili HULMANN</td></tr>
                            <tr><td>Tendalino T-Top in tela con apertura manuale o elettrica</td></tr>
                        </tbody>
                    </Table>
                </div>
                </TabPanel>
            </Tabs>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default Silverado13Tabs;
