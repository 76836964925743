import Configurator from "../component/Configurator"
import customizations from "../assets/silverado_13_customization.json"
import SilveradoHelmet from "../component/common/SilveradoHelmet"
import { PresentationControls, Stage } from "@react-three/drei"
import Chair from "../component/Chair"

const Silverado13Configurator = () => {
    return (<>
        <SilveradoHelmet pageTitle="Configurator" />

        <Configurator basePrice={940_000} customizations={customizations}>
            {/* <Boat /> */}
            <Chair />
        </Configurator>
    </>)
}

export default Silverado13Configurator