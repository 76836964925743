import { Canvas, useFrame, useThree } from "@react-three/fiber"
import Stack from 'react-bootstrap/Stack';
// import Form from 'react-bootstrap/Form';
import { Accordion, AccordionBody, AccordionHeader, Col, Container, Modal, Row, Table, Form } from "react-bootstrap"
import { CustomizationContext, CustomizationProvider } from "../contexts/CustomizationContext"
import { Suspense, useContext, useLayoutEffect, useRef, useState } from "react";
import { FaCamera, FaSlidersH } from "react-icons/fa";
import { mail_estimate } from "../http/contact";
import { Html, OrbitControls, Center, Environment, PresentationControls } from "@react-three/drei";
// import { button, useControls } from "leva";

function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const CubeLoader = () => {
    const myMesh = useRef()

    useFrame(({ clock }) => {
        myMesh.current.rotation.x = clock.getElapsedTime()
        myMesh.current.rotation.y = clock.getElapsedTime()
    })

    return <mesh ref={myMesh}>
        <boxGeometry />
        <meshNormalMaterial />
    </mesh>
}

const Checkout = (props) => {
    const {customizations, canvas} = useContext(CustomizationContext)
    const [showModal, setShowModal] = useState(false)
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const [formState, setFormState] = useState({
        'fullname': '',
        'email': '',
        'telephone': '',
        'extra': ''
    })
    const tableRef = useRef()

    const onFormChange = (e) => {
        setFormState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const onFormSubmit = async (e) => {
        e.preventDefault()
        const fd = new FormData(e.target)
        fd.append('customizations_table', tableRef.current.outerHTML)
        fd.append('canvas_data_url', canvas.canvasGetState().gl.domElement.toDataURL('image/png'))
        await mail_estimate(fd)
    }

    return <>
        <button
            className={`btn rn-btn ${Object.keys(customizations).length ? '' : 'disabled'}`}
            onClick={handleShow}
        >
            Checkout
        </button>

        <Modal size="xl" show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Richiesta di contatto per Silverado 13</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table ref={tableRef}>
                    <thead>
                        <tr><th>Riepilogo</th></tr>
                    </thead>
                    <tbody>
                        {Object.entries(customizations).map(([name, customization]) => <tr key={name}>
                            <td>{name}:</td>
                            <td>{formatNumber(customization.price)} €</td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="fw-bold">Totale:</td>
                            <td className="fw-bold">{formatNumber(props.total)} €</td>
                        </tr>
                    </tfoot>
                </Table>
                <hr/>
                <div>
                    <h5>I tuoi dati di contatto</h5>
                    <form id="form-estimate" className="contact-form--1" onChange={onFormChange} onSubmit={onFormSubmit}>
                        <Row>
                            <Col>
                                <input type="text" name="fullname" placeholder="Nome completo*" defaultValue={formState['fullname']} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input type="email" name="email" placeholder="Email*" defaultValue={formState['email']} required />
                            </Col>
                            <Col>
                                <input type="tel" name="telephone" placeholder="Numero di telefono*" defaultValue={formState['telephone']} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea name="extra" placeholder="Informazioni aggiuntive" defaultValue={formState['extra']} />
                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" form="form-estimate" className="btn rn-btn">Richiedi contatto</button>
            </Modal.Footer>
        </Modal>
    </>
}

const Scene = (props) => {
    const gl = useThree((state) => state.gl)
    // useControls({
    //     screenshot: button(() => {
    //         const link = document.createElement('a')
    //         link.setAttribute('download', 'canvas.png')
    //         link.setAttribute('href', gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
    //         link.click()
    //     })
    // })

    return <Suspense fallback={<CubeLoader />}>
        <PresentationControls>
            <Center>
                {props.children}
            </Center>
        </PresentationControls>
        <Environment preset="city" />
        <Html as='div' calculatePosition={(_, __, size) => [size.width - 120, size.height - 120]}>
            <a 
                className="silverado-configurator__render-button"
                download='canvas.png'
                href={gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream')}
            >
                <FaCamera/>
            </a>
        </Html>
    </Suspense>
}

const _Configurator = (props) => {
    const availableCustomizations = props.customizations
    const {customizations, setCustomizations, canvas} = useContext(CustomizationContext)
    const total = Object.values(customizations).reduce((a,b) => a+b.price, props.basePrice)
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleCustomization = (title, customization) => {
        if(customization) {
            setCustomizations((prev) => ({
                ...prev,
                [title]: customization
            }))
        } else {
            setCustomizations(prev => {
                let {
                    [title]: _,
                    ...rest
                } = prev
                return rest
            })
        }
    }

    const Total = <div className="total">
        <Row>
            <Col>
                <p className="fw-bold">TOTALE {formatNumber(total)} €</p>
            </Col>
            <Col>
                <Checkout total={total} />
            </Col>
        </Row>
    </div>

    const RightEl = <div className={`right ${menuOpen ? 'open' : ''}`}>
        <Accordion flush>
            <Stack gap={4}>
                {Object.keys(availableCustomizations).map((title, i) => {
                    const price = availableCustomizations[title].price
                    const colors = availableCustomizations[title].colors

                    return <Accordion.Item key={i} eventKey={i}>
                        <AccordionHeader>
                            <span className="title">{title}</span>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Form.Check 
                                            type="checkbox"
                                            checked={!!customizations[title]}
                                            onChange={(e) => toggleCustomization(title, e.target.checked && {
                                                price,
                                                color: colors[0]
                                            })} 
                                        />
                                    </Col>
                                    <Col>
                                        <span>{formatNumber(price)} €</span>
                                    </Col>
                                </Row>
                            </Container>
                        </AccordionHeader>
                        <AccordionBody>
                            <Container fluid>
                                <Table borderless>
                                    <tbody>
                                        <tr>
                                            {colors.map((c, j) => (
                                                <td key={j}>
                                                    <div 
                                                        className={`color-dot-wrapper ${customizations[title]?.color.name === c.name ? 'active' : ''}`}
                                                        onClick={() => toggleCustomization(title, {
                                                            price,
                                                            color: c
                                                        })}
                                                    >
                                                        <div
                                                            className="color-dot"
                                                            style={{
                                                                backgroundColor: c.color
                                                            }}
                                                        />
                                                        <div>{c.name}</div>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Container>
                        </AccordionBody>
                    </Accordion.Item>
                })}
            </Stack>
        </Accordion>

        {Total}
    </div>

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const [outerTotalHeight, setOuterTotalHeight] = useState(0)
    const outerTotalRef = useRef()

    useLayoutEffect(() => {
        function updateSize() {
            setOuterTotalHeight(outerTotalRef.current.clientHeight)
        }
        window.addEventListener('resize', updateSize)
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    

    return <>
        <div className="silverado-configurator d-flex">
            <div className="mobile-menu-toggle d-lg-none" onClick={toggleMobileMenu}>
                <FaSlidersH />
            </div>
            <div style={{flex: 1, position: "relative", display: "flex", height: `calc(100% - ${outerTotalHeight}px)`}}>
                <Canvas className="canvas" gl={{ preserveDrawingBuffer: true }} onCreated={({set, get}) => canvas.setCanvasStore({canvasSetState: set, canvasGetState: get})}>
                    <Scene>
                        {props.children}
                    </Scene>
                </Canvas>
                {RightEl}
            </div>
            
            <div ref={outerTotalRef} className="d-lg-none">
                {Total}
            </div>
        </div>
    </>
}

const Configurator = (props) => {
    return <CustomizationProvider>
        <_Configurator basePrice={props.basePrice} customizations={props.customizations}>
            {props.children}
        </_Configurator>
    </CustomizationProvider>
}

export default Configurator