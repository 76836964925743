import { Col, Container, Row } from "react-bootstrap";
import boat1Img from "../assets/images/3d-boats/1.jpg"
import boat2Img from "../assets/images/3d-boats/2.jpg"
import boat3Img from "../assets/images/3d-boats/3.jpg"
import boat4Img from "../assets/images/3d-boats/4.jpg"
import boat5Img from "../assets/images/3d-boats/5.jpg"
import boat6Img from "../assets/images/3d-boats/6.jpg"
import boat7Img from "../assets/images/3d-boats/7.jpg"
import boat8Img from "../assets/images/3d-boats/8.jpg"
import React from "react";
import SilveradoCab from "../elements/SilveradoCab";
import SilveradoPageHead from "../elements/SilveradoPageHead";
import SilveradoHelmet from "../component/common/SilveradoHelmet";
import SilveradoContainerSection from "../elements/common/SilveradoContainerSection";
import Slider from "react-slick";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Silverado",
    description:
        "desc.",
    img: boat1Img,
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 2",
    description:
        "desc.",
    img: boat2Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 3",
    description:
        "desc.",
    img: boat3Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 4",
    description:
        "desc.",
    img: boat4Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 5",
    description:
        "desc.",
    img: boat5Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 6",
    description:
        "desc.",
    img: boat6Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 7",
    description:
        "desc.",
    img: boat7Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Boat 8",
    description:
        "desc.",
    img: boat8Img
    // buttonText: "Configura",
    // buttonLink: "/s13configurator",
  },
];

const boatImgs = [boat1Img, boat2Img, boat3Img, boat4Img, boat5Img, boat6Img, boat7Img, boat8Img]

const Home = () => {
  return <>
  <SilveradoHelmet pageTitle="Home" description=" "/>
  <div className="home-page">
    <section>
      <SilveradoPageHead>
        <Slider
            infinite={true}
            autoplay={true}
            autoplaySpeed={5000}
            speed={2000}
            pauseOnHover={false}
        >
          {SlideList.map((value, index) => (
              <div key={index}>
                <div
                    // className="fullscreen d-flex align-items-center justify-content-center"
                    className="fullscreen d-flex"
                    style={{
                      backgroundImage: `url(${value.img})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundAttachment: 'fixed',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                >
                  <Container>
                    <Row >
                      <Col lg={12} >
                        {/* <div className={`inner ${value.textPosition}`}> */}
                        <div className={`inner`} style={{paddingTop: "24%"}}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                              <h1 className="title theme-gradient">
                                {value.title}
                              </h1>
                          ) : (
                              ""
                          )}
                          {value.description ? (
                              <p className="description">{value.description}</p>
                          ) : (
                              ""
                          )}
                          {value.buttonText ? (
                              <div className="slide-btn">
                                <a
                                    className="rn-button-style--2 btn-solid"
                                    href={`${value.buttonLink}`}
                                >
                                  {value.buttonText}
                                </a>
                              </div>
                          ) : (
                              ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
          ))}
        </Slider>
      </SilveradoPageHead>
    </section>


    <section className="home-page__body bg_color--1">
      <section className={`pt--120`}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">PRE SALE </h2>
                <p>importanza di ordinarlo subito <br/>
                  importanza di ordinarlo subito <br/>
                  importanza di ordinarlo subito <br/></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


    <SilveradoContainerSection>
      <Row>
        <Col xs={12} sm={6}>
          <h2>DESIGN UNICO</h2>
        </Col>
        <Col xs={12} sm={6}>
          DESCRIZIONE
          <br/>
          <a href="https://www.enzomanca.it/" target="_blank">Restyling di ENZO MANCA</a>
        </Col>
      </Row>
    </SilveradoContainerSection>

    <section>
      <Slider
          // infinite={true}
          slidesToShow={3}
          // autoplay={true}
          autoplaySpeed={10000}
          speed={500}
          pauseOnHover={true}
          draggable={false}
      >
        {boatImgs.map((boatImg, i) => <img key={i} src={boatImg}/>)}
      </Slider>
    </section>


    <SilveradoContainerSection className="service-area bg_color--1">
      <SilveradoCab/>
    </SilveradoContainerSection>
  </section>
  </div>
</>
}

export default Home