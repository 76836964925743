import React, { useState } from "react";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import SilveradoHelmet from "../component/common/SilveradoHelmet";
import mapImg from "../assets/images/map-contact.png";
import about from "../assets/images/about/about-6.jpg";
import { mail } from "../http/contact";

const Contacts = () => {
    const [ result,showresult ] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault()
        const fd = new FormData(e.target)
        await mail(fd)
        e.target.reset();
        showresult(true);
    }

    return(
        <React.Fragment>
            <SilveradoHelmet pageTitle='Contatti' />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Cntact With Us</h2>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">
                    
                    <div className="row">
                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiHeadphones />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Contact With Phone Number</h4>
                                    <p><a href="tel:+057 254 365 456">+057 254 365 456</a></p>
                                    <p><a href="tel:+856 325 652 984">+856 325 652 984</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMail />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Email Address</h4>
                                    <p><a href="mailto:admin@gmail.com">admin@gmail.com</a></p>
                                    <p><a href="mailto:example@gmail.com">example@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMapPin />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Location</h4>
                                    <p>5678 Bangla Main Road, cities 580 <br /> GBnagla, example 54786</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                    </div>
                </div>
            </div>
            {/* End Contact Top Area  */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--1">
                {/* <ContactTwo /> */}
                <div className="contact-form--1">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title">Contact Us.</h2>
                                    <p className="description">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    Architecto cupiditate aperiam neque.
                                    </p>
                                </div>
                                <div className="form-wrapper">
                                    <form action="" onSubmit={sendEmail}>
                                        <div className="rn-form-group">
                                            <input 
                                            type="text"
                                            name="fullname"
                                            placeholder="Your Name"
                                            required
                                            />
                                        </div>

                                        <div className="rn-form-group">
                                            <input 
                                            type="email"
                                            name="email"
                                            placeholder="Your Email"
                                            required
                                            />
                                        </div>

                                        <div className="rn-form-group">
                                            <input 
                                            type="text"
                                            name="phone"
                                            placeholder="Phone Number"
                                            required
                                            />
                                        </div>

                                        <div className="rn-form-group">
                                            <input 
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            />
                                        </div>
                                        
                                        <div className="rn-form-group">
                                            <textarea 
                                            name="message"
                                            placeholder="Your Message"
                                            required
                                            >
                                            </textarea>
                                        </div>

                                        <div className="rn-form-group">
                                            <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
                                        </div> 

                                        <div className="rn-form-group">
                                            {result 
                                            ? <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p> 
                                            : null}
                                        </div> 
                                    </form>

                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div style={{marginBottom: "20px"}}>
                                    <img src={mapImg} />
                                </div>
                                <div className="thumbnail mb_md--30 mb_sm--30">
                                    <img src={about} alt="trydo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Page Area  */}

            {/* Start Contact Map  */}
            {/* <div className="rn-contact-map-area position-relative">
                <div style={{ height: '650px', width: '100%' }}>
                    <GoogleMapReact
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    >
                    <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                    />
                    </GoogleMapReact>
                </div>
            </div> */}
            {/* End Contact Map  */}
            

            {/* Start Brand Area */}
            {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Brand Area */}
            
        </React.Fragment>
    )
}
export default Contacts