import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";

export const CustomizationContext = createContext({});

export const CustomizationProvider = (props) => {
    const [customizations, setCustomizations] = useState({})
    const [{canvasGetState, canvasSetState}, setCanvasStore] = useState({})
    return <CustomizationContext.Provider value={{customizations, setCustomizations, canvas: {canvasGetState, canvasSetState, setCanvasStore}}}>
        {props.children}
    </CustomizationContext.Provider>
}

export const useCustomization = () => useContext(CustomizationContext);
