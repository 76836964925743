import { API_URL_INDEX, genericApiPost } from "./http";

const API_CONTACT = `${API_URL_INDEX}&class=contact&action=`


export async function mail(params) {
    const {data} = await genericApiPost(API_CONTACT + 'mail', params)
    console.log(data)
}

export async function mail_estimate(params) {
    const {data} = await genericApiPost(API_CONTACT + 'estimate', params)
    console.log(data)
}